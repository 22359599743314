<template>
  <div id="app">
    <v-app>
      <Toolbar />
      <AppNav />
      <v-content>
        <router-view />
      </v-content>
      <NotificationsManager></NotificationsManager>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import AppNav from "@bugseq-site/admin/src/components/AppNav.vue";
import NotificationsManager from "@bugseq-site/admin/src/components/NotificationsManager.vue";
import Toolbar from "@bugseq-site/admin/src/components/Toolbar.vue";

@Component({
  metaInfo() {
    return {
      title: "BugSeq",
      titleTemplate: "BugSeq | %s"
    };
  },
  components: {
    AppNav,
    NotificationsManager,
    Toolbar,
  },
})
export default class App extends Vue {}
</script>
