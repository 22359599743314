import { getStoreAccessors } from 'typesafe-vuex'
import { ApiState } from './state'
import { RootState } from '../../state'

export const getters = {
  userProfile: (state: ApiState) => state.userProfile,
  jobResults: (state: ApiState) => state.jobResults,
  adminJobs: (state: ApiState) => state.jobs,
  adminIncompleteJobs: (state: ApiState) =>
    state.jobs.filter((j) => j.job_status === 'running'),
  adminBatchJobs: (state: ApiState) => state.batchJobs,
  adminOneUser: (state: ApiState) => (userId: string) => {
    const filteredUsers = state.users.filter((user) => user.id === userId)
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] }
    }
  }
}

const { read } = getStoreAccessors<ApiState, RootState>('api')

export const readUserProfile = read(getters.userProfile)
export const readJobResults = read(getters.jobResults)
export const readAdminOneUser = read(getters.adminOneUser)
export const readAdminJobs = read(getters.adminJobs)
export const readAdminIncompleteJobs = read(getters.adminIncompleteJobs)
export const readAdminBatchJobs = read(getters.adminBatchJobs)
