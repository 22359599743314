<template>
  <v-chip :color="getChipColour()">{{ getChipText() }}</v-chip>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

const ComponentProps = Vue.extend({
  props: {
    status: String,
  },
});

@Component
export default class NextflowTaskStatusChip extends ComponentProps {
  public getChipColour() {
    switch (this.status) {
      case "COMPLETED":
        return "green lighten-4";
      case "FAILED":
        return "red lighten-4";
      case "RUNNING":
      case "NEW":
      case "SUBMITTED":
        return "blue lighten-4";
      case "ABORTED":
      default:
        return "amber lighten-4";
    }
  }

  public getChipText() {
    return (
      this.status.charAt(0).toUpperCase() + this.status.slice(1).toLowerCase()
    );
  }
}
</script>
