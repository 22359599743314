import { getStoreAccessors } from 'typesafe-vuex'
import { components } from '@bugseq-site/admin/src/lib/api/api'
import { RootState } from '../../state'
import { ApiState } from './state'

function compareJobs (a: components['schemas']['JobRunResponse'], b: components['schemas']['JobRunResponse']): number {
  return b.created.getTime() - a.created.getTime()
}

export const mutations = {
  setUserProfile (state: ApiState, payload: components['schemas']['UserResponse']) {
    state.userProfile = payload
  },
  setJobResults (
    state: ApiState,
    { jobId, results }: { jobId: string, results: components['schemas']['JobRunResultsResponse'] }
  ) {
    state.jobResults = { [jobId]: results }
  },
  appendJobs (state: ApiState, payload: Array<components['schemas']['JobRunResponse']>) {
    // filter out updated/refreshed jobs, and then concat new ones
    const newJobIds = new Set(payload.map((j) => j.id))
    const jobs = state.jobs.filter((j) => !newJobIds.has(j.id)).concat(payload)

    jobs.sort(compareJobs)
    state.jobs = jobs
  },
  setBatchJobs (state: ApiState, payload: Array<components['schemas']['BatchJob']>) {
    state.batchJobs = payload
  },
  setUser (state: ApiState, payload: components['schemas']['UserResponse']) {
    const users = state.users.filter(
      (user: components['schemas']['UserResponse']) => user.id !== payload.id
    )
    users.push(payload)
    state.users = users
  },
  setJobRun (state: ApiState, payload: components['schemas']['JobRunResponse']) {
    const jobs = state.jobs.filter((job: components['schemas']['JobRunResponse']) => job.id !== payload.id)
    jobs.push(payload)
    jobs.sort(compareJobs)
    state.jobs = jobs
  }
}

const { commit } = getStoreAccessors<ApiState, RootState>('api')

export const commitSetUserProfile = commit(mutations.setUserProfile)
export const commitSetJobResults = commit(mutations.setJobResults)
export const commitSetJobRun = commit(mutations.setJobRun)
export const commitSetUser = commit(mutations.setUser)
export const commitAppendJobs = commit(mutations.appendJobs)
export const commitSetBatchJobs = commit(mutations.setBatchJobs)
