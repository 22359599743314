import { RouteConfig } from 'vue-router'

import Start from '@bugseq-site/admin/src/views/Start.vue'
import Cognito from '@bugseq-site/admin/src/views/Cognito.vue'
import AdminUsers from '@bugseq-site/admin/src/views/AdminUsers.vue'
import InviteUser from '@bugseq-site/admin/src/views/InviteUser.vue'
import EditUser from '@bugseq-site/admin/src/views/EditUser.vue'
import Jobs from '@bugseq-site/admin/src/views/Jobs.vue'
import JobReview from '@bugseq-site/admin/src/views/JobReview.vue'
import JobDebug from '@bugseq-site/admin/src/views/JobDebug.vue'
import Results from '@bugseq-site/admin/src/views/Results.vue'
import Metrics from '@bugseq-site/admin/src/views/Metrics.vue'

const routes: RouteConfig[] = [
  { path: '/', redirect: '/app' },
  {
    path: '/app',
    redirect: '/app/jobs',
    component: Start,
    children: [
      {
        path: 'cognito',
        component: Cognito
      },
      {
        path: 'users',
        redirect: 'users/all'
      },
      {
        path: 'users/all',
        name: 'users',
        component: AdminUsers
      },
      {
        path: 'users/invite',
        name: 'users-invite',
        component: InviteUser
      },
      {
        path: 'users/edit/:id',
        name: 'users-edit',
        component: EditUser
      },
      {
        path: 'jobs',
        name: 'jobs',
        component: Jobs
      },
      {
        path: 'jobs/:jobId/review',
        name: 'jobs-review',
        component: JobReview
      },
      {
        path: 'jobs/:jobId/debug',
        name: 'jobs-debug',
        component: JobDebug
      },
      {
        path: 'jobs/:jobId/results',
        name: 'jobs-results',
        component: Results
      },
      {
        path: 'metrics',
        name: 'metrics',
        component: Metrics
      }
    ]
  }
]
export default routes
