<template>
  <div class="pa-4 mt-4">
    <div class="mt-2">
      <v-form v-model="valid" lazy-validation>
        <v-text-field
          label="First Name"
          v-model="firstName"
          disabled
        ></v-text-field>
        <v-text-field
          label="Last Name"
          v-model="lastName"
          disabled
        ></v-text-field>
        <v-text-field
          label="Billing Account ID"
          v-model="billingAccountId"
        ></v-text-field>
        <v-select
          label="Data Region"
          :items="regions"
          v-model="pinnedRegion"
        >
        </v-select>
        <v-layout justify-center>
          <v-btn
            @click="reset"
            class="text-capitalize"
          >Reset</v-btn>
          <v-btn
            @click="submit"
            :disabled="!valid"
            color="primary"
            class="text-capitalize"
          >
            Save
          </v-btn>
        </v-layout>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { components } from '@bugseq-site/admin/src/lib/api/api'
import {
  dispatchUpdateUser,
} from "@bugseq-site/admin/src/store/modules/api/actions";

const ComponentProps = Vue.extend({
  props: {
    user: Object,
  },
});

@Component
export default class UserTabDetails extends ComponentProps {
  private valid = true;
  private firstName: string = "";
  private lastName: string = "";
  private billingAccountId: string = "";
  private pinnedRegion?: string | null = null;

  private regions = [
    { text: "Unspecified", value: null },
    { text: "US", value: "us-west-2" },
    { text: "Canada", value: "ca-central-1" },
    { text: "EU", value: "eu-west-1" },
  ];

  private mounted() {
    this.reset();
  }

  private reset() {
    this.$validator.reset();
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
    this.billingAccountId = this.user.billing_account_id;
    this.pinnedRegion = this.user.pinned_region;
  }

  private async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: components["schemas"]["UserUpdateAdminRequest"] = {};
      if (this.billingAccountId) {
        updatedProfile.billing_account_id = this.billingAccountId;
      }
      if (this.pinnedRegion !== this.user.pinned_region) {
        updatedProfile.pinned_region = this.pinnedRegion;
      }
      await dispatchUpdateUser(this.$store, {
        id: this.user!.id,
        user: updatedProfile,
      });
      this.reset()
    }
  }
}
</script>
