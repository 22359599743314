export const parseS3Uri = (uri: string): { bucket: string, prefix?: string } => {
  const trimmed = uri.replace('s3://', '')
  const bucketSeparator = trimmed.indexOf('/')
  if (bucketSeparator === -1) {
    return { bucket: trimmed }
  }

  const [bucket, prefix] = [trimmed.slice(0, bucketSeparator), trimmed.slice(bucketSeparator + 1)]
  return { bucket, prefix }
}

const getAWSLinkWithRole = (linkWithoutRole: string, role: string): string => {
  const params = new URLSearchParams({
    account_id: '642025892278',
    role_name: role,
    destination: linkWithoutRole
  })
  // e.g. https://bugseq.awsapps.com/start/#/console?account_id=642025892278&role_name=prod_ro&destination=https%3A%2F%2Fs3.console.aws.amazon.com%2Fs3%2Fbuckets%2Fbugseq-prod-user-inputs-ca-central-1%3Fprefix%3Djob_inputs%252Fu_AACppK89gPFEbq2C9lKk4IJx%252Fanls_AABTmJTeerVJdoQo076o8rQy
  return `https://bugseq.awsapps.com/start/#/console?${params.toString()}`
}

export const getS3DashboardLink = (parsed: { bucket: string, prefix?: string }): string => {
  // some guesswork on the region
  let region = 'ca-central-1'
  if (parsed.bucket.includes('us-west-2')) {
    region = 'us-west-2'
  }

  // e.g. https://s3.console.aws.amazon.com/s3/buckets/bugseq-dev-nextflow-tmp-ca-central-1?prefix=nextflow/8f/be85cf64c6decd382d319f6248eeb4/
  // some context in here, but seems S3 doesn't expect URLs to be encoded:
  // https://gitlab.com/bugseq/goliath/-/merge_requests/1065
  const prefix = parsed.prefix ?? '/'
  const linkWithoutRole = `https://s3.console.aws.amazon.com/s3/buckets/${parsed.bucket}?prefix=${prefix}&region=${region}`
  return getAWSLinkWithRole(linkWithoutRole, 'prod_ro')
}

export const getBatchDashboardLink = (jobId: string, region: string): string => {
  const url = new URL('/batch/home', `https://${region}.console.aws.amazon.com`)
  url.hash = `jobs/detail/${jobId}`
  return getAWSLinkWithRole(url.toString(), 'prod_ro')
}

export const getBatchLogsLink = (logStreamName: string, region: string): string => {
  const url = new URL('/cloudwatch/home', `https://${region}.console.aws.amazon.com`)
  url.hash = `logEventViewer:group=/aws/batch/job;stream=${logStreamName}`
  return getAWSLinkWithRole(url.toString(), 'prod_ro')
}

export const getECRLink = (image: string): string => {
  // 642025892278.dkr.ecr.ca-central-1.amazonaws.com/bugseq/accessories/boto3:prod-933760c8
  const registrySep = image.indexOf('/')
  const [registry, imageWithTag] = [image.slice(0, registrySep), image.slice(registrySep + 1)]
  const awsAccountId = registry.split('.')[0]
  const region = registry.split('.')[3]
  const imageName = imageWithTag.split(':')[0]

  // https://ca-central-1.console.aws.amazon.com/ecr/repositories/private/642025892278/bugseq/accessories/boto3?region=ca-central-1
  const url = new URL(`/ecr/repositories/private/${awsAccountId}/${imageName}`, `https://${region}.console.aws.amazon.com`)
  return getAWSLinkWithRole(url.toString(), 'prod_ro')
}
