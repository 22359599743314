<template>
  <v-expansion-panel>
    <v-expansion-panel-content
      v-for="section in visibleSections()"
      :key="section.name"
    >
      <template v-slot:header>
        {{ section.name }}
      </template>
      <v-card>
        <v-card-text
          v-if="section.description"
          class="ml-2 grey--text text--darken-2 subheading"
          >{{ section.description }}</v-card-text
        >
        <ResultsTable :section="section" :job="job" />
        <ResultsPanel
          v-if="section.subsections.length > 0"
          :sections="section.subsections"
          :job="job"
          class="pl-4 nested-results-table"
        />
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { components } from "@bugseq-site/admin/src/lib/api/api";
import ResultsTable from "@bugseq-site/admin/src/components/ResultsTable.vue";

export enum PerItemActionType {
  BlastAssembly
}

interface PerItemAction {
  type: PerItemActionType;
}

export interface Section {
  name: string;
  description?: string;
  filterFunc: (f: components["schemas"]["JobRunFile"]) => boolean;
  outputs: components["schemas"]["JobRunFile"][];
  subsections: Section[];
  hidden: boolean;
  perItemActions?: PerItemAction[];
}

const ComponentProps = Vue.extend({
  props: {
    sections: Array as PropType<Section[]>,
    job: String,
  },
});

@Component({
  components: { ResultsTable },
  name: 'ResultsPanel', // https://github.com/kaorun343/vue-property-decorator/issues/102
})
export default class ResultsPanel extends ComponentProps {
  public visibleSections() {
    return this.sections.filter(
      (s) =>
        !s.hidden &&
        (s.outputs.length > 0 ||
          s.subsections.flatMap((o) => o.outputs).length > 0),
    );
  }
}
</script>

<style scoped>
.nested-results-table {
  box-shadow: none;
}
</style>
