<template>
  <Metrics />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Metrics from "@bugseq-site/admin/src/components/Metrics.vue";

@Component({
  components: {
    Metrics,
  },
  metaInfo: {
    title: "Metrics",
  },
})
export default class MetricsPage extends Vue {}
</script>
