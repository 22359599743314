<template>
  <v-app>
    <v-container fluid justify-center d-flex>
      <v-flex class="xs12">
        <JobDebug :jobId="jobId" />
      </v-flex>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import JobDebug from "@bugseq-site/admin/src/components/JobDebug.vue";

@Component({
  metaInfo: {
    title: "Job | Debug",
  },
  components: { JobDebug },
})
export default class JobDebugPage extends Vue {
  public jobId!: string;
  public data() {
    return {
      jobId: this.$route.params.jobId,
    };
  }
}
</script>
