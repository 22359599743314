<template>
  <v-container fluid justify-center d-flex class="pb-5">
    <v-flex class="md12 lg10 xl8">
      <JobResults :job="jobId" admin />
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import JobResults from "@bugseq-site/admin/src/components/JobResults.vue";

@Component({
  components: { JobResults },
  metaInfo: {
    title: "Results",
  },
})
export default class AdminResults extends Vue {
  public jobId!: string;
  public data() {
    return {
      jobId: this.$route.params.jobId,
    };
  }
}
</script>
