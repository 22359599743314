<template>
  <div>
  <v-container>
  <v-layout>
  <v-flex xs5>
    <v-select
      :items="getRuns()"
      @change="selectedRunChanged"
      v-model="selectedRun"
      label="Batch Run"
    ></v-select>
  </v-flex>
  <v-flex xs4 offset-xs3>
    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
  </v-flex>
  </v-layout>
  </v-container>
  <v-data-table
    :headers="headers"
    :items="pageRows"
    :total-items="totalItems"
    :rows-per-page-items="[10, 20, 50]"
    :loading="loading"
    :pagination.sync="pagination"
    class="elevation-1 nextflow-task-table"
  >
    <template v-slot:items="props">
      <td class="py-2">
        <div>{{ props.item.record.name }}</div>
        <div class="mt-1 secondary--text text--lighten-2">
          <a target="_blank" :href="getWorkdirLink(props.item.record.workdir)">{{ props.item.record.task_hash }}</a>
          <CopyableText :copyText="props.item.record.workdir" class="ml-2">(work)</CopyableText>
        </div>
      </td>
      <td class="py-2">
        <div>
          <a v-if="props.item.batch_job" target="_blank" :href="getBatchDashboardLink(props.item.batch_job.aws_batch_job_id, props.item.batch_job.aws_region)">{{ props.item.batch_job.aws_batch_job_id | formatBatchJobId }}</a>
        </div>
        <div class="mt-1 secondary--text text--lighten-2">
          <a v-if="props.item.batch_job" target="_blank" :href="getBatchLogsLink(props.item.batch_job.log_stream_name, props.item.batch_job.aws_region)">Logs</a>
        </div>
      </td>
      <td class="py-2">
        <NextflowTaskStatusChip
          :status="props.item.record.status"
        ></NextflowTaskStatusChip>
      </td>
      <td class="py-2">
        <div>
          <a v-if="props.item.batch_job" target="_blank" :href="getECRLink(props.item.batch_job.image)">{{ props.item.batch_job.image | formatImageName }}</a>
        </div>
        <div class="mt-1 secondary--text text--lighten-2">
          <CopyableText v-if="props.item.batch_job" :copyText="props.item.batch_job.image">{{ props.item.batch_job.image | formatImageTag }}</CopyableText>
        </div>
      </td>
      <td class="py-2">
        <div v-if="props.item.batch_job">CPU: {{ props.item.batch_job.vcpus }}</div>
        <div v-if="props.item.batch_job" class="mt-1">Mem: {{ props.item.batch_job.memory*1024*1024 | formatSize }}</div>
      </td>
      <td class="py-2">
        <div v-if="props.item.batch_job">Created: {{ props.item.batch_job.created_at | formatTime }}</div>
        <div v-if="props.item.batch_job">Started: {{ props.item.batch_job.started_at | formatTime }}</div>
        <div v-if="props.item.batch_job">Stopped: {{ props.item.batch_job.stopped_at | formatTime }}</div>
      </td>
    </template>
  </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import CopyableText from "@bugseq-site/shared/src/components/CopyableText.vue";
import {
  dispatchGetBatchTasks,
  dispatchGetNextflowTasks,
} from "@bugseq-site/admin/src/store/modules/api/actions";
import NextflowTaskStatusChip from "@bugseq-site/admin/src/components/NextflowTaskStatusChip.vue";
import { components } from "@bugseq-site/admin/src/lib/api/api";
import { formatSize, formatTime, debounce } from "@bugseq-site/shared/src/lib/utils";
import { parseS3Uri, getS3DashboardLink, getBatchDashboardLink, getBatchLogsLink, getECRLink } from "@bugseq-site/admin/src/lib/utils";

function formatImageName(image: string): string {
  return image.replace("642025892278.dkr.ecr.ca-central-1.amazonaws.com/bugseq/", "").split(":")[0]
}

function formatImageTag(image: string): string {
  return image.replace("642025892278.dkr.ecr.ca-central-1.amazonaws.com/bugseq/", "").split(":")[1]
}

function formatBatchJobId(raw: string): string {
  return raw.slice(0, 4) + "..." + raw.slice(-4);
}

const ComponentProps = Vue.extend({
  props: {
    jobId: {
      type: String,
    },
    managerBatchJobs: {
      type: Array<components["schemas"]["BatchJob"]>,
      required: true,
    },
  },
});

interface RunInfo {
  runName: string;
  earliestTaskCreated: Date;
}

@Component({
  filters: {
    formatBatchJobId,
    formatImageName,
    formatImageTag,
    formatSize,
    formatTime,
  },
  methods: {
    getBatchDashboardLink,
    getBatchLogsLink,
    getECRLink,
  },
  components: {
    NextflowTaskStatusChip,
    CopyableText,
  },
})
export default class NextflowTaskTable extends ComponentProps {
  private headers = [
    { text: "Name", align: "left", sortable: false, value: "record.name", width: "35%" },
    { text: "Batch ID", align: "left", sortable: false, value: "batch_job.aws_batch_job_id", width: "8%" },
    { text: "Status", align: "left", sortable: false, value: "record.status", width: "8%" },
    { text: "Image", align: "left", sortable: false, value: "batch_job.image", width: "19%" },
    { text: "Resources", align: "left", sortable: false, width: "10%" },
    { text: "Timing", align: "left", sortable: true, value: "record.expiration", width: "20%" },
  ]

  private pagination: {page: number, sortBy: string, descending: boolean} = {
    page: 1,
    sortBy: "record.expiration",
    descending: true,
  };

  private selectedRun: string | null = null
  private pageRows: {record: components["schemas"]["NextflowTaskRecord"], batch_job: components["schemas"]["BatchJob"] | undefined}[] = [];

  private loading: boolean = false;
  private search: string = "";

  // https://stackoverflow.com/a/61451760
  private paginationTokens: (string | null)[] = [null];

  private totalItems: number = 10000
  private cachedNextflowTasks: components["schemas"]["NextflowTaskRecord"][] = [];
  private cachedBatchJobs: components["schemas"]["BatchJob"][] = [];

  private mounted() {
    const runs = this.getRuns();
    if (!runs || runs.length === 0) {
      return;
    }

    this.selectedRun = runs[0].value;
  }

  private getRuns(): { text: string, value: string }[] {
    return [...this.managerBatchJobs]
      .sort((mbj1, mbj2) => mbj2.created_at.getTime() - mbj1.created_at.getTime())
      .map((r: components["schemas"]["BatchJob"]) => ({
        text: r.aws_batch_job_id,
        value: r.aws_batch_job_id,
       }))
  }

  public async selectedRunChanged(selectedRun) {
    this.resetPagination()
    this.paginationChanged(this.pagination)
  }

  @debounce(500)
  @Watch("search")
  public searchChanged() {
    this.resetPagination()
    return this.paginationChanged(this.pagination);
  }

  public resetPagination() {
    this.pagination.page = 1;
    this.paginationTokens = [null];
  }

  @Watch("pagination")
  public async paginationChanged(pagination) {
    // can't query jobs without a job run
    if (!this.selectedRun) {
      return
    }

    const { page, rowsPerPage, sortBy, descending } = pagination
    if (sortBy !== "record.expiration") {
      throw new Error(`invalid sortBy: ${sortBy}`)
    }

    if (page === 1) {
      // if loading the first page, there is no offset specified
      // so this invalidates any saved offsets
      this.resetPagination()
    }

    // for search, we pull all the records at once
    if (!this.search || page === 1) {
      const params = {
        aws_batch_job_id: this.selectedRun,
        LastEvaluatedKey: this.paginationTokens[page-1],
        limit: this.search ? null : rowsPerPage,
        search: this.search,
      }
      const nextflowTasksResp = await dispatchGetNextflowTasks(this.$store, {
        jobId: this.jobId,
        params: params,
      })
      this.paginationTokens[page] = nextflowTasksResp.LastEvaluatedKey
      if (!nextflowTasksResp.LastEvaluatedKey) {
        this.totalItems = (page-1)*rowsPerPage + nextflowTasksResp.tasks.length
      }
      this.cachedNextflowTasks = nextflowTasksResp.tasks
    }
    let nextflowTasks: components["schemas"]["NextflowTaskRecord"][] = []
    if (this.search) {
      nextflowTasks = this.cachedNextflowTasks.slice((page-1)*rowsPerPage, page*rowsPerPage)
    } else {
      nextflowTasks = this.cachedNextflowTasks
    }

    let batchJobs: components['schemas']['BatchJob'][] = this.cachedBatchJobs
    const existingBatchJobIds = new Set(batchJobs.map(bj => bj.aws_batch_job_id))
    const requiredBatchJobs = nextflowTasks.map(t => t.native_id).filter(rbj => !existingBatchJobIds.has(rbj))
    if (requiredBatchJobs.length > 0) {
      this.loading = true;
      try {
        const newBatchJobs = await dispatchGetBatchTasks(
          this.$store,
          {
            jobId: this.jobId,
            params: { id: requiredBatchJobs },
          },
        );
        this.cachedBatchJobs = batchJobs.concat(newBatchJobs)
      } finally {
        this.loading = false;
      }
    }
    this.pageRows = nextflowTasks.map(nt => ({record: nt, batch_job: this.cachedBatchJobs.find(bj => nt.native_id === bj.aws_batch_job_id)}))
  }

  private getWorkdirLink(workdir: string): string {
    // nextflow doesn't annotate workdirs with trailing /
    // but s3 urls need the trailing / or it assumes it's an object
    let withTrailing = workdir;
    if (!withTrailing.endsWith("/")) {
      withTrailing += "/"
    }

    const parsed = parseS3Uri(withTrailing)
    return getS3DashboardLink(parsed)
  }
}
</script>

<style>
.nextflow-task-table td {
  overflow-wrap: anywhere;
}
</style>
