<template>
  <v-navigation-drawer
    persistent
    :mini-variant="miniDrawer"
    v-model="showDrawer"
    fixed
    app
  >
    <v-layout column fill-height>
      <v-list subheader>
        <v-list-group
          v-model="expandAdmin"
          prepend-icon="playlist_add"
          no-action
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Pages</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <v-list-tile
            v-for="item in adminItems"
            :key="item.title"
            :to="item.to"
          >
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
      </v-list>
      <v-spacer></v-spacer>
      <v-list>
        <v-list-tile @click="logout">
          <v-list-tile-action>
            <v-icon>close</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile @click="switchMiniDrawer">
          <v-list-tile-action>
            <v-icon
              v-html="miniDrawer ? 'chevron_right' : 'chevron_left'"
            ></v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Collapse</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
} from "@bugseq-site/admin/src/store/modules/drawer/getters";
import {
  dispatchSetDashboardShowDrawer,
  dispatchSetDashboardMiniDrawer,
} from "@bugseq-site/admin/src/store/modules/drawer/actions";
import { dispatchUserLogOut } from "@bugseq-site/admin/src/store/modules/api/actions";

@Component
export default class AppNav extends Vue {
  private expandAdmin: boolean = false;
  private adminItems = [
    {
      to: { name: "users" },
      icon: "group",
      title: "Users"
    },
    {
      to: { name: "users-invite" },
      icon: "person_add",
      title: "Invite User"
    },
    {
      to: { name: "jobs" },
      icon: "backup_table",
      title: "Analyses"
    },
    {
      to: { name: "metrics" },
      icon: "trending_up",
      title: "Metrics"
    },
  ]

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    dispatchSetDashboardShowDrawer(this.$store, value);
  }

  public switchMiniDrawer() {
    dispatchSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
</script>
