<template>
  <div>
    <v-snackbar auto-height :color="currentNotificationColor" :value="firstNotification">
      <v-layout justify-center align-center>
        <v-progress-circular
          class="mx-2"
          indeterminate
          v-show="currentNotificationProgress"
        ></v-progress-circular>
        <div class="mx-2 my-2 google-font">{{ currentNotificationContent }}</div>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Notification } from "@bugseq-site/admin/src/store/modules/notifications/state";
import { readFirstNotification } from "@bugseq-site/admin/src/store/modules/notifications/getters";

@Component
export default class NotificationsManager extends Vue {
  public get firstNotification() {
    return readFirstNotification(this.$store)
  }

  public get currentNotificationContent() {
    return this.firstNotification?.content
  }

  public get currentNotificationColor() {
    return this.firstNotification?.color ?? "info"
  }

  public get currentNotificationProgress() {
    return this.firstNotification?.showProgress ?? false
  }
}
</script>
