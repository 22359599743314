<template>
  <v-container fluid justify-center d-flex>
    <v-flex class="xs12">
      <div class="headline my-2">Running</div>
      <v-data-table
        :headers="headers"
        :items="runningJobs"
        :total-items="totalJobs"
        :pagination.sync="runningJobsPagination"
        :rows-per-page-items="[5, 20]"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <td class="py-3">
            <div class="mr-3">
              <div>
                {{ props.item.user_provided_name || props.item.id }}
              </div>
              <div
                v-if="props.item.user_provided_name"
                class="mt-1 secondary--text text--lighten-2"
              >
                {{ props.item.id }}
              </div>
            </div>
          </td>
          <td>
            <JobResultsChip :jobStatus="props.item.job_status" />
          </td>
          <td class="py-3">
            <div class="mr-3">
              <div>
                {{ props.item.results_email_address }}
              </div>
              <div
                class="mt-1"
                v-if="!props.item.is_academic"
              >
                <router-link :to="{ name: 'users-edit', params: { id: props.item.owner_id }}" target="_blank">{{ props.item.owner_id }}</router-link>
              </div>
              <div
                class="mt-1"
                v-else
              >
                Academic
              </div>
            </div>
          </td>
          <td class="text-no-wrap">{{ props.item.created | formatTime }}</td>
          <td class="text-no-wrap">
            <BatchJobStatusChip
              :status="latestBatchStatus(props.item.id)"
              v-if="latestBatchStatus(props.item.id)"
            ></BatchJobStatusChip>
          </td>
          <td>
            <v-btn
              color="primary"
              class="text-capitalize"
              :to="{ name: 'jobs-review', params: { jobId: props.item.id } }"
              target="_blank"
              >Review</v-btn
            >
            <v-btn
              class="text-capitalize"
              :to="{ name: 'jobs-debug', params: { jobId: props.item.id } }"
              target="_blank"
              >Debug</v-btn
            >
          </td>
        </template>
      </v-data-table>
      <div class="mt-4">
        <span class="headline">All</span><v-spacer></v-spacer>
      </div>
      <v-layout align-end>
        <v-flex>
          <v-text-field
            v-model="search"
            label="Search (must be of format owner:<owner_id> or id:<id>, e.g. owner:6)"
            prepend-icon="mdi-database-search"
          ></v-text-field>
        </v-flex>
        <v-flex>
          <v-autocomplete
            v-model="userSearchSelected"
            :items="userSearchResults"
            item-text="email"
            :filter="userFilter"
            :search-input.sync="userSearch"
            v-on:change="userSelect"
            label="User Search (name/email)"
            prepend-icon="mdi-account-search"
            return-object
          >
          <template v-slot:selection="data">
            <v-chip
              close
              @input="clearUserSearch()"
            >
              {{ data.item.first_name }} {{ data.item.last_name }} ({{ data.item.email }})
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-tile-content>
              <v-list-tile-title>{{ data.item.first_name }} {{ data.item.last_name }} ({{ data.item.email }})</v-list-tile-title>
              <v-list-tile-sub-title>{{ data.item.id }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="headers"
        :items="allJobs"
        :total-items="totalJobs"
        :pagination.sync="allJobsPagination"
        :rows-per-page-items="[10, 25, 50]"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <td class="py-3">
            <div class="mr-3">
              <div>
                {{ props.item.user_provided_name || props.item.id }}
              </div>
              <div
                v-if="props.item.user_provided_name"
                class="mt-1 secondary--text text--lighten-2"
              >
                {{ props.item.id }}
              </div>
            </div>
          </td>
          <td>
            <JobResultsChip :jobStatus="props.item.job_status" />
          </td>
          <td class="py-3">
            <div class="mr-3">
              <div>
                {{ props.item.results_email_address }}
              </div>
              <div
                class="mt-1"
                v-if="!props.item.is_academic"
              >
                <router-link :to="{ name: 'users-edit', params: { id: props.item.owner_id }}" target="_blank">{{ props.item.owner_id }}</router-link>
              </div>
              <div
                class="mt-1"
                v-else
              >
                Academic
              </div>
            </div>
          </td>
          <td class="text-no-wrap">{{ props.item.created | formatTime }}</td>
          <td class="text-no-wrap">
            <BatchJobStatusChip
              :status="latestBatchStatus(props.item.id)"
              v-if="latestBatchStatus(props.item.id)"
            ></BatchJobStatusChip>
          </td>
          <td>
            <v-btn
              color="primary"
              class="text-capitalize"
              :to="{ name: 'jobs-review', params: { jobId: props.item.id } }"
              target="_blank"
              >Review</v-btn
            >
            <v-btn
              class="text-capitalize"
              :to="{ name: 'jobs-debug', params: { jobId: props.item.id } }"
              target="_blank"
              >Debug</v-btn
            >
          </td>
        </template>
      </v-data-table>
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { components } from '@bugseq-site/admin/src/lib/api/api'
import { dispatchGetJobs, dispatchGetBatchJobs, dispatchGetUsers } from "@bugseq-site/admin/src/store/modules/api/actions";
import { readAdminBatchJobs } from "@bugseq-site/admin/src/store/modules/api/getters";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";
import JobResultsChip from "@bugseq-site/shared/src/components/JobResultsChip.vue";
import BatchJobStatusChip from "@bugseq-site/admin/src/components/BatchJobStatusChip.vue";
import { debounce } from "@bugseq-site/shared/src/lib/utils";

@Component({
  filters: {
    formatTime,
  },
  components: {
    JobResultsChip,
    BatchJobStatusChip,
  },
  metaInfo: {
    title: "Jobs",
  },
})
export default class Jobs extends Vue {
  private headers = [
    {
      text: "Id",
      align: "left",
      sortable: false,
      value: "id",
    },
    {
      text: "", // status
      align: "left",
      sortable: false,
      value: "job_status",
    },
    {
      text: "Owner",
      align: "left",
      sortable: false,
      value: "owner_id",
    },
    {
      text: "Created",
      align: "left",
      sortable: true,
      value: "created",
    },
    {
      text: "Latest Batch Status",
      align: "left",
      sortable: false,
      value: "",
    },
    {
      align: "left",
      text: "",
      sortable: false,
    },
  ];
  private allJobsPagination = {
    sortBy: "created",
    descending: true,
  };
  private runningJobsPagination = {
    sortBy: "created",
    descending: true,
  };
  private allJobs: components["schemas"]["JobRunResponse"][] = [];
  private runningJobs: components["schemas"]["JobRunResponse"][] = [];
  private totalJobs = 1000; // hopefully we never go this far back, and computing a real total is wasteful
  private search = "";

  private userSearch: string = "";
  private userSearchResults: components["schemas"]["UserResponse"][] = [];
  private userSearchSelected: components["schemas"]["UserResponse"] | null = null;

  public mounted() {
    dispatchGetBatchJobs(this.$store);
  }

  @debounce(300)
  @Watch("userSearch")
  public async userSearchChanged(search: string | null) {
    if (!search || search.length < 3) {
      return
    }

    const users = await dispatchGetUsers(this.$store, {
      limit: 10,
      skip: 0,
      q: search,
    });
    this.userSearchResults = users;
  }

  private userFilter(item: components["schemas"]["UserResponse"], queryText: string): boolean {
    // this is a bit dirty, but our backend does filtering
    // so no need to filter here.
    return true;
  }

  private userSelect(user: components["schemas"]["UserResponse"]) {
    this.search = `owner:${user.id}`
  }

  private clearUserSearch() {
    this.search = ''
    this.userSearch = ''
    this.userSearchSelected = null
  }

  @Watch("allJobsPagination", { deep: true })
  public async allJobsPaginationChanged() {
    return this.paginationChanged(this.allJobsPagination, true);
  }

  @debounce(300)
  @Watch("search", { deep: true })
  public async searchChanged() {
    return this.paginationChanged(this.allJobsPagination, true);
  }

  @Watch("runningJobsPagination", { deep: true })
  public async runningPaginationChanged(pagination) {
    return this.paginationChanged(pagination, false);
  }

  public async paginationChanged(pagination, isAllJobs) {
    const { sortBy, descending, page, rowsPerPage } = pagination;
    if (sortBy !== "created" || descending !== true) {
      throw new Error(
        `only sort-by: created and descending: true are supported, found (${sortBy}, ${descending})`,
      );
    }

    const opts: {
      limit: number;
      skip: number;
      job_statuses?: components["schemas"]["JobStatus"][];
      owner_id?: string;
      id?: string;
    } = {
      limit: rowsPerPage,
      skip: (page - 1) * rowsPerPage,
    };
    if (!isAllJobs) {
      opts.job_statuses = ["created", "pending", "running"];
    }

    const search = this.search.replace(/\s/g, "");

    if (
      search.startsWith("owner:") &&
      search.length > "owner:".length &&
      search.length === "owner:u_".length + 24
    ) {
      opts.owner_id = search.replace(/^owner:/, "");
    } else if (
      search.startsWith("id:") &&
      search.length > "id:".length &&
      search.length === "id:anls_".length + 24
    ) {
      opts.id = search.replace(/^id:/, "");
    } else if (search) {
      // user isnt done typing search
      return;
    }

    const jobs = await dispatchGetJobs(this.$store, opts);
    if (isAllJobs) {
      this.allJobs = jobs!;
    } else {
      this.runningJobs = jobs!;
    }
  }

  public latestBatchStatus(jobId: string): string | null {
    const batchJobs = readAdminBatchJobs(this.$store)
      .filter((j) => j.tags['bugseq-job-id'] === jobId)
      .sort(
        (a: components["schemas"]["BatchJob"], b: components["schemas"]["BatchJob"]) =>
          b.created_at.getTime() - a.created_at.getTime(),
      );
    if (batchJobs.length > 0) {
      return batchJobs[0].status;
    }

    return null;
  }
}
</script>
