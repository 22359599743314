<template>
  <v-app>
    <v-container fluid justify-center d-flex>
      <v-flex class="md12 lg10">
        <JobReview :jobId="jobId" />
      </v-flex>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import JobReview from "@bugseq-site/admin/src/components/JobReview.vue";

@Component({
  metaInfo: {
    title: "Job | Review",
  },
  components: { JobReview },
})
export default class JobReviewPage extends Vue {
  public jobId!: string;
  public data() {
    return {
      jobId: this.$route.params.jobId,
    };
  }
}
</script>
