<template>
  <div>
    <v-toolbar light>
      <v-toolbar-title>
        Manage Users
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="users"
      :pagination.sync="pagination"
      :total-items="totalUsers"
      :rows-per-page-items="rowsPerPageItems"
    >
      <template slot="items" slot-scope="props">
        <td>{{ props.item.id }}</td>
        <td>{{ props.item.email }}</td>
        <td>{{ props.item.first_name }} {{ props.item.last_name }}</td>
        <td>{{ props.item.created | formatTime }}</td>
        <td class="justify-center layout px-0">
          <v-tooltip top>
            <span>Edit</span>
            <v-btn
              slot="activator"
              flat
              :to="{
                name: 'users-edit',
                params: { id: props.item.id },
              }"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import { components } from "@bugseq-site/admin/src/lib/api/api"
import { formatTime } from "@bugseq-site/shared/src/lib/utils";
import { dispatchGetUsers } from "@bugseq-site/admin/src/store/modules/api/actions";
import { debounce } from "@bugseq-site/shared/src/lib/utils";

interface Pagination {
  sortBy: 'created';
  descending: true;
  page: number;
  rowsPerPage: number;

}

@Component({
  filters: {
    formatTime,
  },
  metaInfo: {
    title: "Users",
  },
})
export default class AdminUsers extends Vue {
  private rowsPerPageItems: number[] = [5, 10, 20];
  private pagination: Pagination = {
    sortBy: "created",
    descending: true,
    page: 1,
    rowsPerPage: this.rowsPerPageItems[0],
  };
  private users: components["schemas"]["UserResponse"][] = [];

  private totalUsers = 10000; // hopefully we never go this far back, and computing a real total is wasteful
  private search: string = "";

  private headers = [
    {
      text: "ID",
      sortable: false,
      value: "id",
      align: "left",
    },
    {
      text: "Email",
      sortable: false,
      value: "email",
      align: "left",
    },
    {
      text: "Name",
      sortable: false,
      align: "left",
    },
    {
      text: "Created",
      sortable: true,
      value: 'created',
      align: "left",
    },
    {
      text: "Actions",
      value: "id",
    },
  ];

  public mounted() {
    this.paginationChanged(this.pagination)
  }

  @debounce(300)
  @Watch("search", { deep: true })
  private async searchChanged() {
    return this.paginationChanged(this.pagination);
  }

  @Watch("pagination", { deep: true })
  private async paginationChanged(pagination) {
    const { sortBy, descending, page, rowsPerPage } = pagination;
    if (sortBy !== "created" || descending !== true) {
      throw new Error(
        `only sort-by: created and descending: true are supported, found (${sortBy}, ${descending})`,
      );
    }

    const opts: {
      limit: number;
      skip: number;
      q?: string;
    } = {
      limit: rowsPerPage,
      skip: (page - 1) * rowsPerPage,
    };

    if (this.search && this.search.length < 3) {
      return
    } else if (this.search) {
      opts.q = this.search
    }

    const users = await dispatchGetUsers(this.$store, opts);
    this.users = users
  }
}
</script>
