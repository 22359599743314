<template>
  <div class="pa-4 mt-4">
    <div class="subheading font-weight-bold">
      Sample Credits
    </div>
    <div class="my-3">
      When a user submits samples for analysis, those consume sample credits. Users see how many credits they have on the submission page.
    </div>
    <div class="mt-2">
      <v-data-table
        :headers="sampleCreditHeaders"
        :items="sampleCredits"
        :total-items="10"
        :pagination.sync="sampleCreditsPagination"
        :rows-per-page-items="[5, 20]"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <td>
            {{ props.item.id }}
          </td>
          <td>
            {{ props.item.sample_type }}
          </td>
          <td>
            {{ props.item.remaining_count }}/{{ props.item.initial_count }}
          </td>
          <td>
            {{ props.item.created | formatTime }}
          </td>
          <td>
            {{ props.item.expiration | formatTime }}
            <PopOut
              v-if="isExpired(props.item.expiration)"
              icon="warning"
              icon-color="orange"
            >
              These credits are expired
            </PopOut>
          </td>
          <td>
            {{ props.item.internal_notes }}
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="subheading font-weight-bold mt-4">
      Add Sample Credits
    </div>
    <div class="mt-2">
      <v-form data-vv-scope="sample">
        <v-layout wrap>
          <v-flex xs12 md4 class="pr-3">
            <v-text-field
              label="Initial Count"
              v-model="sampleInitialCount"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 class="px-3">
            <v-select
              label="Sample Type"
              :items="sampleTypes"
              v-model="sampleType"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4 class="pl-3">
            <v-text-field
              label="Expiration"
              v-model="sampleExpiration"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Internal Notes (e.g. PO number, or explanation)"
              v-model="sampleInternalNotes"
              v-validate="'required'"
              data-vv-name="Internal Notes"
              :error-messages="errors.collect('Internal Notes', 'sample')"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-btn
          @click="submitSamples"
          color="primary"
          class="text-capitalize ml-0"
        >
          Add
        </v-btn>
      </v-form>
    </div>
    <div class="subheading font-weight-bold mt-4">
      Seats
    </div>
    <div class="my-3">
      When a user joins a lab, it consumes a seat on the account. Seats are deduplicated so a user joining 5 labs still consumes only a single seat on the account.
    </div>
    <div class="mt-2">
      <v-data-table
        :headers="userCreditHeaders"
        :items="userCredits"
        :total-items="10"
        :pagination.sync="userCreditsPagination"
        :rows-per-page-items="[5, 20]"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <td>
            {{ props.item.id }}
          </td>
          <td>
            {{ props.item.remaining_count }}/{{ props.item.initial_count }}
          </td>
          <td>
            {{ props.item.created | formatTime }}
          </td>
          <td>
            {{ props.item.expiration | formatTime }}
            <PopOut
              v-if="isExpired(props.item.expiration)"
              icon="warning"
              icon-color="orange"
            >
              These credits are expired
            </PopOut>
          </td>
          <td>
            {{ props.item.internal_notes }}
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="subheading font-weight-bold mt-4">
      Add Seats
    </div>
    <div class="mt-2">
      <v-form data-vv-scope="user">
        <v-layout wrap>
          <v-flex xs12 md6 class="pr-3">
            <v-text-field
              label="Initial Count"
              v-model="userInitialCount"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pl-3">
            <v-text-field
              label="Expiration"
              v-model="userExpiration"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Internal Notes (e.g. PO number, or explanation)"
              v-model="userInternalNotes"
              v-validate="'required'"
              data-vv-name="Internal Notes"
              :error-messages="errors.collect('Internal Notes', 'user')"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-btn
          @click="submitUsers"
          color="primary"
          class="text-capitalize ml-0"
        >
          Add
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import { components } from '@bugseq-site/admin/src/lib/api/api'
import {
  dispatchAddBillingSampleCredits,
  dispatchGetBillingSampleCredits,
  dispatchAddBillingUserCredits,
  dispatchGetBillingUserCredits,
} from "@bugseq-site/admin/src/store/modules/api/actions";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";

const ComponentProps = Vue.extend({
  props: {
    user: Object,
  },
});

@Component({
  components: {
    PopOut,
  },
  filters: {
    formatTime,
  }
})
export default class UserTabBilling extends ComponentProps {
  private sampleCreditHeaders = [
    {
      text: "Id",
      align: "left",
      sortable: false,
      value: "id",
    },
    {
      text: "Sample Type",
      align: "left",
      sortable: false,
      value: "sample_type",
    },
    {
      text: "Remaining",
      align: "left",
      sortable: false,
      value: "remaining_count",
    },
    {
      text: "Created",
      align: "left",
      sortable: true,
      value: "created",
    },
    {
      text: "Expiration",
      align: "left",
      sortable: false,
      value: "expiration",
    },
    {
      text: "Internal Notes",
      align: "left",
      sortable: false,
      value: "internal_notes",
    },
  ];

  private sampleCreditsPagination = {
    sortBy: "created",
    descending: true,
  };

  private sampleCredits: components['schemas']['BillingAccountSampleCreditAdminResponse'][] = []

  private sampleTypes = [
    { text: "Any", value: "any_sample_type" },
    { text: "Metagenomic", value: "metagenomic" },
    { text: "Isolate", value: "isolate" },
    { text: "16S", value: "sixteen_s" },
  ]

  private sampleInitialCount: string = "10"
  private sampleType: components['schemas']['SampleType'] | null = null
  private sampleExpiration: string
  private sampleInternalNotes: string = ""

  private userCreditHeaders = [
    {
      text: "Id",
      align: "left",
      sortable: false,
      value: "id",
    },
    {
      text: "Remaining",
      align: "left",
      sortable: false,
      value: "remaining_count",
    },
    {
      text: "Created",
      align: "left",
      sortable: true,
      value: "created",
    },
    {
      text: "Expiration",
      align: "left",
      sortable: false,
      value: "expiration",
    },
    {
      text: "Internal Notes",
      align: "left",
      sortable: false,
      value: "internal_notes",
    },
  ];

  private userCreditsPagination = {
    sortBy: "created",
    descending: true,
  };

  private userCredits: components['schemas']['BillingAccountUserCreditAdminResponse'][] = []

  private userInitialCount: string = "10"
  private userExpiration: string
  private userInternalNotes: string = ""

  constructor() {
    super()
    const expiration = new Date()
    expiration.setFullYear(expiration.getFullYear() + 1)
    this.sampleExpiration = expiration.toISOString().substring(0, "2022-02-02".length)
    this.userExpiration = expiration.toISOString().substring(0, "2022-02-02".length)
  }

  private async mounted() {
    const [sampleCredits, userCredits] = await Promise.all([
      dispatchGetBillingSampleCredits(this.$store, { billingAccountId: this.user.billing_account_id }),
      dispatchGetBillingUserCredits(this.$store, { billingAccountId: this.user.billing_account_id }),
    ])
    this.sampleCredits = sampleCredits!.items
    this.userCredits = userCredits!.items
  }

  private isExpired(expiration: Date) {
    return expiration < new Date()
  }

  private async submitSamples() {
    if (!this.sampleType) {
      return
    }

    if (!(await this.$validator.validate("sample.*"))) {
      return
    }

    const body = {
      initial_count: parseInt(this.sampleInitialCount),
      remaining_count: parseInt(this.sampleInitialCount),
      sample_type: this.sampleType,
      expiration: new Date(this.sampleExpiration),
      internal_notes: this.sampleInternalNotes,
    }
    const credit = await dispatchAddBillingSampleCredits(this.$store, {
      billingAccountId: this.user.billing_account_id,
      body,
    })

    this.sampleCredits.push(credit)
    this.sampleInitialCount = "0"
    this.sampleType = null
    this.sampleInternalNotes = ""

    // reset so the empty fields don't throw errors
    await this.$validator.reset()
  }

  private async submitUsers() {
    if (!(await this.$validator.validate("user.*"))) {
      return
    }

    const body = {
      initial_count: parseInt(this.userInitialCount),
      remaining_count: parseInt(this.userInitialCount),
      expiration: new Date(this.userExpiration),
      internal_notes: this.userInternalNotes,
    }
    const credit = await dispatchAddBillingUserCredits(this.$store, {
      billingAccountId: this.user.billing_account_id,
      body,
    })

    this.userCredits.push(credit)
    this.userInitialCount = "0"
    this.userInternalNotes = ""

    // reset so the empty fields don't throw errors
    await this.$validator.reset()
  }
}
</script>
