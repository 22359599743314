import { components } from '@bugseq-site/admin/src/lib/api/api'

export function parseNextflowTaskRecord (d: components['schemas']['NextflowTaskRecord']): components['schemas']['NextflowTaskRecord'] {
  return {
    ...d,
    expiration: new Date(d.expiration)
  }
}

export function parseBatchJob (d: components['schemas']['BatchJob']): components['schemas']['BatchJob'] {
  return {
    ...d,
    created_at: new Date(d.created_at),
    started_at: (d.started_at != null) ? new Date(d.started_at) : null,
    stopped_at: (d.stopped_at != null) ? new Date(d.stopped_at) : null
  }
}

export function parseUser (u: components['schemas']['UserResponse']): components['schemas']['UserResponse'] {
  return {
    ...u,
    created: new Date(u.created)
  }
}

export function parseJobRunResponse (d: components['schemas']['JobRunResponse']): components['schemas']['JobRunResponse'] {
  return {
    ...d,
    created: new Date(d.created),
    end_time: (d.end_time != null) ? new Date(d.end_time) : null
  }
}

export function parseJobRunResultsResponse (r: components['schemas']['JobRunResultsResponse']): components['schemas']['JobRunResultsResponse'] {
  return {
    ...r,
    created: new Date(r.created),
    end_time: (r.end_time != null) ? new Date(r.end_time) : null
  }
}

export function parseJobRunAdminListResponse (d: components['schemas']['JobRunAdminListResponse']): components['schemas']['JobRunAdminListResponse'] {
  return {
    ...d,
    created: new Date(d.created),
    end_time: (d.end_time != null) ? new Date(d.end_time) : null
  }
}

export function parseBillingAccountSampleCreditAdminResponse (r: components['schemas']['BillingAccountSampleCreditAdminResponse']): components['schemas']['BillingAccountSampleCreditAdminResponse'] {
  return {
    ...r,
    created: new Date(r.created),
    expiration: new Date(r.expiration)
  }
}

export function parseBillingAccountUserCreditAdminResponse (r: components['schemas']['BillingAccountUserCreditAdminResponse']): components['schemas']['BillingAccountUserCreditAdminResponse'] {
  return {
    ...r,
    created: new Date(r.created),
    expiration: new Date(r.expiration)
  }
}
