import Vue from 'vue'
import Vuetify from 'vuetify'
import VeeValidate from 'vee-validate'
import createClient from 'openapi-fetch'
import App from './App.vue'
import Vuex from 'vuex'
import { createStore } from './store/store'
import 'vuetify/dist/vuetify.min.css'
import '@bugseq-site/shared/src/style.css'
import AsyncComputed from 'vue-async-computed'
import VueMeta from 'vue-meta'
import Router from 'vue-router'
import { paths } from '@bugseq-site/admin/src/lib/api/api'
import { getApiUrl } from '@bugseq-site/shared/src/env'
import { registerAuth } from '@bugseq-site/shared/src/lib/api/auth'
import routes from './routes'

Vue.config.productionTip = false

Vue.use(Vuetify, {
  iconfont: ['md', 'mdi', 'fa']
})
Vue.use(VeeValidate)
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes
})

Vue.use(Vuex)

const client = createClient<paths>({ baseUrl: getApiUrl() })

Vue.use(AsyncComputed)
Vue.use(VueMeta)

registerAuth()

new Vue({
  router,
  store: createStore(client, router),
  render: (h) => h(App)
}).$mount('#app')
