<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Invite User</div>
      </v-card-title>
      <v-card-text>
        <div class="title">1. Enter User Information</div>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            label="First Name"
            v-model="firstName"
          ></v-text-field>
          <v-text-field
            label="Last Name"
            v-model="lastName"
          ></v-text-field>
          <v-text-field
            label="Affiliation"
            v-model="affiliation"
          ></v-text-field>
          <v-text-field
            label="Sign Up Code"
            v-model="signupCode"
          ></v-text-field>
        </v-form>
        <div class="title">2. Send Them This Link</div>
        <CopyableText :copy-text="generateLink()"><code class="link">{{generateLink()}}</code></CopyableText>
        <div class="title">3. Guide Them Through Signup</div>
        <ol>
          <li>Have them share their screen</li>
          <li>Send the link</li>
          <li>Change from Login to Signup at the bottom</li>
          <li>Have them put in email/password, and then get the code from their email</li>
          <li>The form should be prefilled. They can click register.</li>
        </ol>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { components } from '@bugseq-site/admin/src/lib/api/api'
import { getAppUrl } from "@bugseq-site/shared/src/env";
import { urlSafeBase64Encode } from "@bugseq-site/shared/src/lib/utils";
import type { InviteLinkParameters } from "@bugseq-site/app/src/lib/types";
import CopyableText from "@bugseq-site/shared/src/components/CopyableText.vue";

// https://stackoverflow.com/a/1349426
function makeid(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

@Component({
  components: {
    CopyableText,
  },
  metaInfo: {
    title: "User | Invite",
  },
})
export default class InviteUser extends Vue {
  private valid = true;
  private firstName: string = "";
  private lastName: string = "";
  private affiliation: string = "";
  private signupCode: string = `free-ten-${makeid(6)}`;

  private generateLink() {
    const params: InviteLinkParameters = {
      firstName: this.firstName,
      lastName: this.lastName,
      affiliation: this.affiliation,
      signupCode: this.signupCode,
      terms: true,
    }
    const code = urlSafeBase64Encode(params)
    return `${getAppUrl()}/register?personalizedcode=${code}`
  }
}
</script>

<style scoped>
.link {
  word-break: break-all;
}
</style>
