// errors really don't play well with openapi-fetch
// revisit when we upgrade openapi-fetch (last noted Jan 11, 2024 - there is an updated version available)
/* eslint @typescript-eslint/no-throw-literal: 0 */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */
import { ActionContext } from 'vuex'
import { getStoreAccessors } from 'typesafe-vuex'
import { signInWithRedirect, signOut } from 'aws-amplify/auth'
import { RootState } from '../../state'
import { ApiState } from './state'
import { components, paths } from '@bugseq-site/admin/src/lib/api/api'
import { getAuthHeaders, CognitoSessionError } from '@bugseq-site/shared/src/lib/api/auth'
import { BugSeqApiError, isRawBugSeqApiError } from '@bugseq-site/shared/src/lib/api/errors'
import { dispatchAddNotification, dispatchRemoveNotification } from '@bugseq-site/admin/src/store/modules/notifications/actions'
import {
  commitSetUserProfile,
  commitSetJobResults,
  commitSetBatchJobs,
  commitSetJobRun,
  commitSetUser
} from './mutations'
import {
  parseNextflowTaskRecord,
  parseBatchJob,
  parseJobRunResponse,
  parseJobRunResultsResponse,
  parseJobRunAdminListResponse,
  parseUser,
  parseBillingAccountSampleCreditAdminResponse,
  parseBillingAccountUserCreditAdminResponse
} from '@bugseq-site/admin/src/lib/api/parse'

type ApiContext = ActionContext<ApiState, RootState>

export const actions = {
  async checkApiError (context: ApiContext, payload: any) {
    if ((payload instanceof CognitoSessionError) || (payload instanceof BugSeqApiError && payload.message === 'User not found')) {
      const existingParams = new URLSearchParams(window.location.search)

      // don't clobber redirect if it exists, and multiple of these are racing
      let redirect = existingParams.get('redirect')
      if (redirect === null) {
        redirect = context.state.router.currentRoute.path
      }
      await signInWithRedirect({ provider: { custom: 'bugseq.com' }, customState: JSON.stringify({ redirect }) })
      return
    }
    let errMsg = 'Error'
    if (payload instanceof BugSeqApiError) {
      errMsg += `: ${payload.message}`
    }
    await dispatchAddNotification(context, {
      color: 'error',
      content: errMsg
    })
  },
  async getUserProfile (context: ApiContext) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/users/me', { headers: await getAuthHeaders() })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUserProfile(context, parseUser(data!))
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async checkLoggedIn (context: ApiContext) {
    const { data, error } = await context.state.client.GET('/v1/admin/users/me', { headers: await getAuthHeaders() })
    if (error !== undefined) {
      if (isRawBugSeqApiError(error)) {
        throw new BugSeqApiError(error.detail)
      }
      throw error
    }
    commitSetUserProfile(context, parseUser(data!))
  },
  async userLogOut (context: ApiContext) {
    await dispatchAddNotification(context, { content: 'Logged out', color: 'success' })
    await signOut() // this will redirect
  },
  async getUser (context: ApiContext, { id }: { id: string }) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/users/{user_id}', {
        headers: await getAuthHeaders(),
        params: { path: { user_id: id } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUser(context, data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getUsers (
    context: ApiContext,
    payload: paths['/v1/admin/users/']['get']['parameters']['query']
  ): Promise<Array<components['schemas']['UserResponse']>> {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/users/', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.map(parseUser)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getJobs (
    context: ApiContext,
    payload: paths['/v1/admin/jobs/admin']['get']['parameters']['query']
  ) {
    try {
      let { data, error } = await context.state.client.GET('/v1/admin/jobs/admin', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data = data!.map(parseJobRunAdminListResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getBatchJobs (context: ApiContext) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/jobs/batch', {
        headers: await getAuthHeaders()
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetBatchJobs(context, data!.map(parseBatchJob))
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getAdminJobInfo (
    context: ApiContext,
    { jobId }: { jobId: string }
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/jobs/admin/{job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: jobId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.job = parseJobRunResponse(data!.job)
      data!.batch_jobs = data!.batch_jobs.map(parseBatchJob)
      return data!
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getNextflowTasks (
    context: ApiContext,
    { jobId, params }: { jobId: string, params: paths['/v1/admin/jobs/admin/{job_id}/nextflow-tasks']['get']['parameters']['query'] }
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/jobs/admin/{job_id}/nextflow-tasks', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: jobId }, query: params }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.tasks = data!.tasks.map(parseNextflowTaskRecord)
      return data!
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getBatchTasks (
    context: ApiContext,
    { jobId, params }: { jobId: string, params: paths['/v1/admin/jobs/admin/{job_id}/batch-tasks']['get']['parameters']['query'] }
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/jobs/admin/{job_id}/batch-tasks', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: jobId }, query: params }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.map(parseBatchJob)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async cancelBatchJob (
    context: ApiContext,
    payload: { awsBatchJobId: string }
  ) {
    const loadingNotification = {
      content: 'Killing!!!!!!',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.DELETE('/v1/admin/jobs/batch/{aws_batch_job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { aws_batch_job_id: payload.awsBatchJobId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Job killed',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async runJob (
    context: ApiContext,
    payload: { id: string, params: components['schemas']['RunJobRunRequest'] }
  ) {
    const loadingNotification = { content: 'running', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/admin/process/run/{job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: payload.id } },
        body: payload.params
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Job kicked off',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async updateJobRun (
    context: ApiContext,
    payload: { id: string, jobRun: components['schemas']['JobRunUpdateRequest'] }
  ) {
    const loadingNotification = { content: 'updating', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      let { data, error } = await context.state.client.PUT('/v1/admin/jobs/{job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: payload.id } },
        body: payload.jobRun
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data = parseJobRunResponse(data!)
      commitSetJobRun(context, data)
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Job updated successfully',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async completeJob (
    context: ApiContext,
    payload: { id: string, body: components['schemas']['JobRunCompleteRequest'] }
  ) {
    const loadingNotification = { content: 'Sending', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.PUT('/v1/admin/jobs/{job_id}/complete', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: payload.id } },
        body: payload.body
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Sent!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async cloneJob (
    context: ApiContext,
    payload: { id: string }
  ) {
    const loadingNotification = { content: 'Cloning', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { data, error } = await context.state.client.POST('/v1/admin/process/{job_id}/clone', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: payload.id } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Cloned!',
        color: 'success'
      })
      return data!
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getAdminJobInputs (
    context: ApiContext,
    payload: { jobId: string }
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/process/{job_id}/inputs', {
        headers: await getAuthHeaders(),
        params: {
          path: { job_id: payload.jobId }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.inputs.map(input => ({ ...input, s3_restore_request_date: (input.s3_restore_request_date != null) ? new Date(input.s3_restore_request_date) : null }))
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async adminRestoreJobInputs (
    context: ApiContext,
    payload: { jobId: string }
  ) {
    const loadingNotification = { content: 'Initiating Restore', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/admin/process/{job_id}/inputs/restore', {
        headers: await getAuthHeaders(),
        params: {
          path: { job_id: payload.jobId }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Async restore initiated. Refresh page to ensure files are actually restoring.',
        color: 'warning'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async updateUser (
    context: ApiContext,
    payload: { id: string, user: components['schemas']['UserUpdateAdminRequest'] }
  ) {
    const loadingNotification = { content: 'saving', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { data, error } = await context.state.client.PUT('/v1/admin/users/{user_id}', {
        headers: await getAuthHeaders(),
        params: {
          path: { user_id: payload.id }
        },
        body: payload.user
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUser(context, data!)
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'User successfully updated',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async generateApiKey (context: ApiContext, payload: { id: string }) {
    const loadingNotification = { content: 'Generating', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/admin/users/{user_id}/apikeys', {
        headers: await getAuthHeaders(),
        params: {
          path: { user_id: payload.id }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Generated',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getMetrics (
    context: ApiContext
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/metrics/', {
        headers: await getAuthHeaders()
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.users = data!.users.map(parseUser)
      data!.jobs = data!.jobs.map(parseJobRunAdminListResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getBillingSampleCredits (context: ApiContext, { billingAccountId }: { billingAccountId: string }) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/billing/{billing_account_id}/credits/sample', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: billingAccountId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountSampleCreditAdminResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async addBillingSampleCredits (
    context: ApiContext,
    payload: { billingAccountId: string, body: components['schemas']['BillingAccountSampleCreditCreateAdminRequest'] }
  ) {
    try {
      const { data, error } = await context.state.client.POST('/v1/admin/billing/{billing_account_id}/credits/sample', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: payload.billingAccountId } },
        body: payload.body
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseBillingAccountSampleCreditAdminResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getBillingUserCredits (context: ApiContext, { billingAccountId }: { billingAccountId: string }) {
    try {
      const { data, error } = await context.state.client.GET('/v1/admin/billing/{billing_account_id}/credits/user', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: billingAccountId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountUserCreditAdminResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async addBillingUserCredits (
    context: ApiContext,
    payload: { billingAccountId: string, body: components['schemas']['BillingAccountUserCreditCreateAdminRequest'] }
  ) {
    try {
      const { data, error } = await context.state.client.POST('/v1/admin/billing/{billing_account_id}/credits/user', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: payload.billingAccountId } },
        body: payload.body
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseBillingAccountUserCreditAdminResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getResults (
    context: ApiContext,
    { jobId }: { jobId: string }
  ) {
    const params = { path: { job_id: jobId } }

    try {
      let { data, error } = await context.state.client.GET('/v1/admin/process/{job_id}/results', {
        headers: await getAuthHeaders(),
        params
      })

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (error) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data = parseJobRunResultsResponse(data!)
      commitSetJobResults(context, {
        jobId,
        results: data
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getFileLink (
    context: ApiContext,
    payload: { jobId: string, filename: string }
  ) {
    const params = {
      path: { job_id: payload.jobId },
      query: { filename: payload.filename }
    }

    try {
      const { data, error } = await context.state.client.GET('/v1/admin/process/{job_id}/results/download', {
        headers: await getAuthHeaders(),
        params
      })

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (error) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.url
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  }
}

const { dispatch } = getStoreAccessors<ApiState, RootState>('api')

export const dispatchCheckApiError = dispatch(actions.checkApiError)
export const dispatchGetUsers = dispatch(actions.getUsers)
export const dispatchGetUser = dispatch(actions.getUser)
export const dispatchCheckLoggedIn = dispatch(actions.checkLoggedIn)
export const dispatchUserLogOut = dispatch(actions.userLogOut)
export const dispatchGetResults = dispatch(actions.getResults)
export const dispatchGetFileLink = dispatch(actions.getFileLink)
export const dispatchGetJobs = dispatch(actions.getJobs)
export const dispatchGetBatchJobs = dispatch(actions.getBatchJobs)
export const dispatchGetAdminJobInfo = dispatch(actions.getAdminJobInfo)
export const dispatchGetNextflowTasks = dispatch(actions.getNextflowTasks)
export const dispatchGetBatchTasks = dispatch(actions.getBatchTasks)
export const dispatchCancelBatchJob = dispatch(actions.cancelBatchJob)
export const dispatchRunJob = dispatch(actions.runJob)
export const dispatchUpdateJobRun = dispatch(actions.updateJobRun)
export const dispatchCompleteJob = dispatch(actions.completeJob)
export const dispatchCloneJob = dispatch(actions.cloneJob)
export const dispatchGetAdminJobInputs = dispatch(
  actions.getAdminJobInputs
)
export const dispatchAdminRestoreJobInputs = dispatch(
  actions.adminRestoreJobInputs
)
export const dispatchUpdateUser = dispatch(actions.updateUser)
export const dispatchGenerateApiKey = dispatch(actions.generateApiKey)
export const dispatchGetMetrics = dispatch(actions.getMetrics)
export const dispatchGetBillingSampleCredits = dispatch(actions.getBillingSampleCredits)
export const dispatchAddBillingSampleCredits = dispatch(actions.addBillingSampleCredits)
export const dispatchGetBillingUserCredits = dispatch(actions.getBillingUserCredits)
export const dispatchAddBillingUserCredits = dispatch(actions.addBillingUserCredits)
