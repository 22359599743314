<template>
  <div>
    <div class="pa-4" v-if="user">
      <v-card class="pa-4">
        <div class="mx-4">
          <div class="pb-2 headline">
            Edit User
          </div>
          <div>
            ID: <CopyableText :copyText="user.id">{{ user.id }}</CopyableText>
          </div>
          <div>
            Email: <CopyableText :copyText="user.email">{{ user.email }}</CopyableText>
          </div>
          <div class="pb-3">
            Signed Up: {{ user.created | formatTime }}
          </div>
        </div>
        <v-tabs fixed-tabs class="mt-3">
          <v-tab
            v-for="tab in tabs"
            :key="tab.name"
          >
            {{ tab.name }}
          </v-tab>
          <v-tab-item
            key="Details"
          >
            <UserTabDetails :user="user" />
          </v-tab-item>
          <v-tab-item
            key="Billing"
          >
            <UserTabBilling :user="user" />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
    <div v-else>
      <v-layout justify-center>
        <v-progress-circular
          indeterminate
          color="black"
        ></v-progress-circular>
      </v-layout>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { components } from '@bugseq-site/admin/src/lib/api/api'
import UserTabDetails from '@bugseq-site/admin/src/components/UserTabDetails.vue'
import UserTabBilling from '@bugseq-site/admin/src/components/UserTabBilling.vue'
import CopyableText from "@bugseq-site/shared/src/components/CopyableText.vue";
import {
  dispatchGetUser,
} from "@bugseq-site/admin/src/store/modules/api/actions";
import { readAdminOneUser } from "@bugseq-site/admin/src/store/modules/api/getters";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";

@Component({
  filters: {
    formatTime,
  },
  components: {
    CopyableText,
    UserTabDetails,
    UserTabBilling,
  },
  metaInfo: {
    title: "User | Edit",
  },
})
export default class EditUser extends Vue {
  private tabs = [
    { name: "Details" },
    { name: "Billing" },
  ]

  public async mounted() {
    await dispatchGetUser(this.$store, {
      id: this.$router.currentRoute.params.id,
    });
  }

  get user() {
    return readAdminOneUser(this.$store)(this.$router.currentRoute.params.id);
  }
}
</script>
