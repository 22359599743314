<template>
  <v-card class="ma-3 google-font full-size-font" v-if="jobInfo">
    <v-card-title primary-title>
      <div class="headline primary--text">Review: {{ jobId }}</div>
    </v-card-title>
    <v-card-text>
      <div class="copyable-fields">
        <div>Name:</div>
        <div>{{ jobInfo.job.user_provided_name }}</div>
        <div>Created:</div>
        <div>{{ jobInfo.job.created | formatTime }}</div>
        <div>Owner:</div>
        <router-link :to="{ name: 'users-edit', params: { id: jobInfo.job.owner_id }}" target="_blank">{{ jobInfo.job.owner_id }}</router-link>
        <div>Status</div>
        <div>
          <JobResultsChip :jobStatus="jobInfo.job.job_status" />
        </div>
        <div class="copyable-fields-col">Email:</div>
        <div>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.results_email_address"
            >{{ jobInfo.results_email_address }}</CopyableText
          >
        </div>
        <div class="copyable-fields-col">Results URL:</div>
        <div>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.job.results_url"
            >{{ jobInfo.job.results_url }}</CopyableText
          >
        </div>
        <div class="copyable-fields-col">Run Options:</div>
        <div>
        <code>{{
            JSON.stringify(JSON.parse(jobInfo.run_options), null, 2)
          }}</code>
        </div>
      </div>
      <h3 class="headline mt-4">Admin</h3>
      <v-form class="mt-3">
        <div>
          <v-btn
            v-on:click="toggleShowCompletionOptions"
            v-if="!showCompletionOptions"
            class="ml-0"
          >
            Show Completion Options
          </v-btn>
          <div v-if="showCompletionOptions">
            <v-checkbox v-model="skipBilling">
              <template v-slot:label>
                <span class="mr-2">Skip Billing?</span>
                <PopOut icon="info">Will complete the job without billing the user. See the billing runbook for more details. This is generally a safe option.</PopOut>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div class="red--text">
          Careful, this will mark the job as complete, and email the user.
        </div>
        <v-btn class="ml-0 mr-2" color="orange lighten-4" v-on:click="completeJob(jobInfo.job.id)">
          <v-icon class="mr-2">receipt</v-icon> Complete Job
        </v-btn>
        <v-btn :to="{ name: 'jobs-debug', params: { jobId: jobInfo.job.id } }">
          Debug Page
        </v-btn>
        <div class="mt-4">
          Other options (these are rarely needed)
        </div>
        <v-btn
          v-on:click="toggleShowMoreActions"
          v-if="!showMoreActions"
          class="mx-0"
        >
          Show More Actions
        </v-btn>
        <div v-if="showMoreActions">
          <div class="mt-4">
            <v-text-field
              box
              type="text"
              v-model="failureMessage"
              label="User-facing failure message"
              prepend-inner-icon="feedback"
            ></v-text-field>
            <v-btn
              class="mx-0"
              color="orange lighten-4"
              v-on:click="failJob(jobInfo.job.id)"
            >
              <v-icon class="mr-2">error</v-icon> Fail and Email User
            </v-btn>
          </div>
          <div class="mt-4">
            <div>
              These will change the job status that the user sees, but will not notify them:
            </div>
            <v-btn
              class="ml-0 mr-2"
              v-on:click="changeStatus(jobInfo.job.id, 'success')"
            >
              <v-icon class="mr-2">check_circle</v-icon> Mark Succeeded
            </v-btn>
            <v-btn
              class="mx-2"
              v-on:click="changeStatus(jobInfo.job.id, 'failure')"
            >
              <v-icon class="mr-2">error</v-icon> Mark Failed
            </v-btn>
            <v-btn
              class="mx-2"
              v-on:click="changeStatus(jobInfo.job.id, 'marked_in_error')"
            >
              <v-icon class="mr-2">error</v-icon> Mark In Error
            </v-btn>
            <v-btn
              class="mx-2"
              v-on:click="changeStatus(jobInfo.job.id, 'running')"
            >
              <v-icon class="mr-2">autorenew</v-icon> Mark Running
            </v-btn>
          </div>
          <div class="mt-4">
            <div>
              Other misc options:
            </div>
            <v-btn class="ml-0 mr-2" color="orange lighten-4" v-on:click="cloneJob(jobInfo.job.id)">
              <v-icon class="mr-2">content_copy</v-icon> <span class="mr-2">Clone Job</span>
              <PopOut icon="info">This creates a new job with a new ID that will immediately be visible to the user. The new job will use the same inputs as this job. Results will not be copied to the new job. The user will be billed and results will be shared when the job completes.</PopOut>
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-card-text>
      <h3 class="headline">Results</h3>
      <v-btn :to="{ name: 'jobs-results', params: { jobId } }" target="_blank" color="primary"
        >Open Results Demo</v-btn
      >
      <JobResults :job="jobId" />
    </v-card-text>
  </v-card>
  <v-card class="ma-3 google-font full-size-font" v-else>
    <v-progress-circular
      indeterminate
      :width="2"
      color="black"
      class="ma-3"
    ></v-progress-circular>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CopyableText from "@bugseq-site/shared/src/components/CopyableText.vue";
import JobResultsChip from "@bugseq-site/shared/src/components/JobResultsChip.vue";
import JobResults from "@bugseq-site/admin/src/components/JobResults.vue";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import { components } from "@bugseq-site/admin/src/lib/api/api";
import {
  dispatchUpdateJobRun,
  dispatchCompleteJob,
  dispatchCloneJob,
  dispatchGetAdminJobInfo,
} from "@bugseq-site/admin/src/store/modules/api/actions";
import { formatTime } from "@bugseq-site/shared/src/lib/utils";

const ComponentProps = Vue.extend({
  props: {
    jobId: String,
  },
});

@Component({
  filters: {
    formatTime,
  },
  components: {
    CopyableText,
    JobResultsChip,
    JobResults,
    PopOut,
  },
})
export default class JobReview extends ComponentProps {
  public jobInfo: components["schemas"]["JobRunAdminResponse"] | null = null;
  public showMoreActions: boolean = false;
  public showCompletionOptions: boolean = false;

  public skipBilling: boolean = false;
  public failureMessage: string = "";

  public async mounted() {
    const jobInfo = await dispatchGetAdminJobInfo(this.$store, {
      jobId: this.jobId,
    });
    this.jobInfo = jobInfo!;
  }

  public toggleShowCompletionOptions() {
    this.showCompletionOptions = !this.showCompletionOptions;
  }

  public toggleShowMoreActions() {
    this.showMoreActions = !this.showMoreActions;
  }

  private changeStatus(id, jobStatus) {
    dispatchUpdateJobRun(this.$store, {
      id,
      jobRun: { job_status: jobStatus },
    });
  }

  private completeJob(id) {
    dispatchCompleteJob(
      this.$store,
      {
        id,
        body: {
          job_status: "success",
          skip_billing: this.skipBilling
        },
      },
    );
  }

  private failJob(id) {
    dispatchCompleteJob(
      this.$store,
      {
        id,
        body: {
          job_status: "failure",
          failure_message: this.failureMessage.trim(),
        },
      },
    );
  }

  private async cloneJob(id) {
    const resp = await dispatchCloneJob(this.$store, { id })
    await this.$router.push({ name: "jobs-debug", params: { jobId: resp.id } })
  }
}
</script>

<style scoped>
.copyable-fields {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content;
  grid-gap: 2px 20px;
}

.copyable-fields-col {
  white-space: nowrap;
}
</style>
