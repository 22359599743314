<template>
  <div class="pa-4">
    <div class="pb-3 headline">
      Metrics
    </div>
    <div class="pa-4" v-if="metrics">
      <v-card class="pa-4">
        <div class="pa-4">
          <div class="subheading font-weight-bold">
            Weekly Metrics <span class="grey--text"> (vs. T28)</span>
          </div>
          <v-layout class="mt-5">
            <v-flex xs4 v-for="coreMetric in coreMetrics()" :key="coreMetric.title">
              <v-layout column align-center>
                <v-flex class="font-weight-bold">
                  <v-layout row align-center>
                    <v-icon>{{ coreMetric.icon }}</v-icon>
                    <span class="ml-1">{{ coreMetric.title }}</span>
                  </v-layout>
                </v-flex>
                <v-flex class="pt-2">
                  <span v-if="coreMetric.title === 'Turnaround Time'">
                    {{ coreMetric.short | formatDuration }}
                    <span class="grey--text"> / {{ coreMetric.long | formatDuration }}</span>
                  </span>
                  <span v-else>
                    {{ coreMetric.short }}
                    <span class="grey--text"> / {{ coreMetric.long }}</span>
                  </span>
                </v-flex>
                <v-flex>
                  <v-icon v-if="coreMetric.trend >= 0" class="green--text">trending_up</v-icon>
                  <v-icon v-else class="red--text">trending_down</v-icon>
                  {{ coreMetric.trend | formatTrend }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <div class="pa-4">
          <div class="subheading font-weight-bold">
            New Users
          </div>
          <v-data-table
            :headers="newUsersTableHeaders"
            :pagination.sync="newUsersTablePagination"
            :items="getNewUsersTableItems()"
            class="elevation-1 mt-3"
          >
            <template v-slot:items="props">
              <td>{{ props.item.first_name }} {{ props.item.last_name }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.affiliation }}</td>
              <td>{{ props.item.created | formatTime }}</td>
              <td><span :class="{'red--text': props.item.trials_used === 0, 'green--text': props.item.trials_used > 0}">{{ props.item.trials_used }}</span></td>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
    <div v-else>
      <v-layout justify-center>
        <v-progress-circular
          indeterminate
          color="black"
        ></v-progress-circular>
      </v-layout>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { components } from "@bugseq-site/admin/src/lib/api/api";
import { dispatchGetMetrics } from "@bugseq-site/admin/src/store/modules/api/actions";
import { formatTime, formatDuration } from "@bugseq-site/shared/src/lib/utils";

function formatTrend(trend: number) {
  return Number(trend)
    .toLocaleString(
      undefined,
      {
        style: 'percent',
        minimumFractionDigits: 2,
      }
    ); 
}

function calculatePercentile(numbers: number[], percentile: number): number {
  if (percentile < 0 || percentile > 1) {
    throw new Error("percentile must be in range [0,1]")
  }

  if (numbers.length === 0) {
      throw new Error("The list of numbers is empty.");
  }

  numbers.sort((a, b) => a - b);

  const index = Math.ceil(percentile * numbers.length) - 1;

  return numbers[index];
}

@Component({
  filters: {
    formatTrend,
    formatTime,
    formatDuration,
  },
})
export default class Metrics extends Vue {
  private metrics: components["schemas"]["InternalMetricsResponse"] | null = null;
  private newUsersTableHeaders = [
    { text: "Name", value: "name" },
    { text: "Email", value: "email" },
    { text: "Affiliation", value: "affiliation" },
    { text: "Created", value: "created" },
    { text: "Trials Used", value: "trials_used" },
  ]
  private newUsersTablePagination = {
    sortBy: "created",
    descending: true,
  }

  private async mounted() {
    const metrics = await dispatchGetMetrics(this.$store)
    if (metrics) {
      this.metrics = metrics
    }
  }

  private coreMetrics() {
    if (!this.metrics) {
      return []
    }

    // plenty of Math.max to avoid divide-by-zero

    const shortPeriod = new Date()
    shortPeriod.setDate(shortPeriod.getDate() - 7)

    const longPeriod = new Date()
    longPeriod.setDate(longPeriod.getDate() - 28)

    const newUsersCountShort = this.metrics.users.filter(u => u.created > shortPeriod).length
    const newUsersCountLong = Math.max(this.metrics.users.filter(u => u.created > longPeriod).length, 1)

    const shortJobs = this.metrics.jobs.filter(j => j.created > shortPeriod)
    const longJobs = this.metrics.jobs.filter(j => j.created > longPeriod)

    const newJobsCountShort = shortJobs.length
    const newJobsCountLong = Math.max(longJobs.length, 1)

    const allTurnaroundTimeSecondsShort = shortJobs
      .filter(j => j.end_time !== null)
      .map(j => (j.end_time!.getTime() - j.created.getTime()) / 1000)
    const allTurnaroundTimeSecondsLong = longJobs
      .filter(j => j.end_time !== null)
      .map(j => (j.end_time!.getTime() - j.created.getTime()) / 1000)

    const turnaroundTimeSecondsShort = allTurnaroundTimeSecondsShort.length > 1 ? calculatePercentile(allTurnaroundTimeSecondsShort, 0.8) : 0
    const turnaroundTimeSecondsLong = allTurnaroundTimeSecondsLong.length > 1 ? calculatePercentile(allTurnaroundTimeSecondsLong, 0.8) : 1

    return [
      {
        icon: "person_add",
        title: "New Users",
        short: newUsersCountShort,
        long: newUsersCountLong,

        // e.g. (6 this week / (20 last 4 weeks / 4 = 5)) - 1 = 6/5 - 1 = 20% increase
        trend: newUsersCountShort / (newUsersCountLong / 4) - 1,
      },
      {
        icon: "add_chart",
        title: "Job Runs",
        short: newJobsCountShort,
        long: newJobsCountLong,

        // e.g. (6 this week / (20 last 4 weeks / 4 = 5)) - 1 = 6/5 - 1 = 20% increase
        trend: newJobsCountShort / (newJobsCountLong / 4) - 1,
      },
      {
        icon: "timer",
        title: "Turnaround Time (P80 vs. 3hr)",
        short: turnaroundTimeSecondsShort,
        long: turnaroundTimeSecondsLong,

        // e.g. (10,000 seconds / 10,800 seconds - 1) * -1 = 7.4% favourable to target
        trend: ((turnaroundTimeSecondsShort / 3*60*60) - 1) * -1,
      },
    ]
  }

  private getNewUsersTableItems() {
    if (!this.metrics) {
      return []
    }

    const shortPeriod = new Date()
    shortPeriod.setDate(shortPeriod.getDate() - 7)

    return this
      .metrics
      .users
      .filter(u => u.created >= shortPeriod)
      .map(u => ({
        first_name: u.first_name,
        last_name: u.last_name,
        email: u.email,
        created: u.created,
        affiliation: u.affiliation,
        trials_used: this.metrics!.jobs.filter(j => j.owner_id === u.id).length,
      }))
  }
}
</script>
